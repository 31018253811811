import React, { useEffect, useState } from 'react'
import FButton from '../../../components/FButton'
import { BiEdit } from 'react-icons/bi'
import {
  HiOutlineAnnotation,
  HiOutlinePhone,
  HiOutlinePhoneOutgoing,
  HiPhoneMissedCall,
} from 'react-icons/hi'
import FIconWrapper from '../../../components/FIconWrapper'
import FAvatar from '../../../components/FAvatar'
import { Link, useParams } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FModal from '../../../components/FModal'
import { Formik } from 'formik'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import ESpinner from '../../../components/ESpinner'
import * as Yup from 'yup'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import FProductTag from '../../../components/FProductTag'
import {
  getProductById,
  getProductInstallmentById,
} from '../../../helpers/utils'
import Transactions from './Transactions'
import moment from 'moment'
import FRow from '../../../components/FRow'
import CallModal from '../Calls/CallModal'
import { BadgeCheck, BadgeDollarSign, ScrollText } from 'lucide-react'
import CustomerResiduals from './CustomerResiduals'
import CustomerRefunds from './CustomerRefunds'
import CustomerNewSubModal from './CustomerNewSubModal'

const CustomerDetails = () => {
  const { phoneNumber } = useParams()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isEditRequestLoading, setIsEditRequestLoading] = useState(false)
  const [isCallModalOpen, setIsCallModalOpen] = useState(false)
  useEffect(() => {
    Refetch()
    transactions.Refetch()
  }, [phoneNumber])

  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `/customer/getCustomer?phoneNumber=${phoneNumber}`,
  })

  const ARCHIVESOURCE = {
    UNREACHABLE: 'لا يمكن الوصول إليه',
    RESCHEDULE: 'إعادة جدولة',
    UPDATE_CALL: 'تحديث المكالمة',
  }

  const interactionTypes = [
    'DONE',
    'MISSING_DATA_DONE',
    'NOT_NEEDED',
    'REFUSE',
    'RESCHEDULE',
    'SOLD',
    'UNREACHABLE',
    'WRONG_NUMBER',
  ]

  const interactionTypesString = interactionTypes.join('&interactionTypes=')

  const callsLogs = useCustomAxios({
    method: 'GET',
    url: `call/archive?interactionTypes=${interactionTypesString}&phoneNumber=${phoneNumber}&endDate=${new Date().toISOString()}&page=0&size=50`,
  })

  const transactionInfoReader = [
    {
      fieldName: 'merchantArea',
      label: 'منطقة التاجر',
    },
  ]

  const transactions = useCustomAxios({
    method: 'GET',
    url: `/customer/${phoneNumber}/transaction`,
  })

  const CustomerCardInfo = () => {
    const name = response?.customer?.name
    return (
      <>
        <div className={'border bg-white p-5 dark:bg-gray-900'}>
          <div className={'flex items-start  justify-between '}>
            <div className={'flex items-start gap-2 '}>
              <div className={'flex gap-2'}>
                <FAvatar name={name} />
                <div className={'flex flex-col'}>
                  <span
                    className={
                      'block whitespace-nowrap text-lg font-bold leading-5'
                    }
                  >
                    {name ?? 'اسم العميل غير محدد'}
                  </span>
                  <FIconWrapper>
                    <HiOutlinePhone />
                    <span className={'block text-sm text-gray-600 underline'}>
                      <span>{response?.customer?.phoneNumber}</span>
                    </span>
                  </FIconWrapper>
                </div>
              </div>

              <div className={'flex flex-wrap gap-1'}>
                {response.customerSubscription.length === 0 && (
                  <div className={'flex gap-2 flex-col'}>
                    <span>لا يوجد مكالمات حاليا</span>
                    <small>سوف يتم ظهور المكالمات هنا</small>
                  </div>
                )}
                {response.customerSubscription.map((item, keyId) => (
                  <Link key={keyId} to={`/subscriptionDup/${item.id}`}>
                    <div key={item.id}>
                      <FProductTag
                        color={item.cancelled ? 'red' : 'white'}
                        productName={
                          <div
                            className={
                              'flex items-center justify-center gap-2 p-1'
                            }
                          >
                            <span className={'text-xs'}>
                              {getProductById(item.productId)?.name}
                            </span>
                            <span className={'text-xs'}>
                              {getProductInstallmentById(
                                item.productInstallmentId,
                              )?.installmentDesc ?? ''}
                            </span>
                            {item?.documentNumber && (
                              <ScrollText
                                size={15}
                                className={'text-green-700'}
                              />
                            )}
                            {item?.dateOFLastPaidInstallment && (
                              <BadgeDollarSign
                                className={'text-yellow-400'}
                                size={15}
                              />
                            )}
                            {item?.completed && (
                              <BadgeCheck
                                className={'text-blue-500'}
                                size={15}
                              />
                            )}
                          </div>
                        }
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <FRow>
              <FButton onClick={() => setIsEditModalOpen(true)}>
                <div className={'flex justify-center gap-2'}>
                  <span className={'text-sm  '}>تعديل</span>
                  <BiEdit size={20} />
                </div>
              </FButton>

              <FButton
                type={'button'}
                onClick={() => setIsCallModalOpen(true)}
                btnType={'secondary'}
              >
                المكالمات
              </FButton>
              <CustomerNewSubModal phoneNumber={phoneNumber} />
            </FRow>
          </div>
        </div>
      </>
    )
  }
  const Cell = ({ attribute, value, children }) => (
    <div className={'flex flex-col'}>
      <span className={'text-right text-xs font-bold text-orient-500'}>
        {' '}
        {attribute}
      </span>
      <span className={'text-right text-sm'}> {value}</span>
    </div>
  )
  const ActivityLog = ({ color, text, time }) => (
    <div className={'flex items-center justify-center gap-2'}>
      <div
        className={`f-row-between items-center justify-center rounded-full p-2 bg-${color}-600  border text-white border-${color}-800`}
      >
        <HiPhoneMissedCall className={''} />
      </div>
      <div
        className={
          'f-row-between flex-grow rounded border border-gray-300  bg-gray-100 px-2 py-3 dark:bg-gray-600'
        }
      >
        <span className={'text-sm font-medium'}> {text} </span>
        <span
          className={'text-sm font-bold  text-gray-500 dark:text-gray-100  '}
        >
          {' '}
          {time}{' '}
        </span>
      </div>
    </div>
  )
  const CustomerContactDetails = ({}) => {
    const [chosenDetails, setChosenDetails] = useState('transactions')
    return (
      <>
        <div className={'w-full  p-5'}>
          {chosenDetails === 'docs' && (
            <>
              <div className={'f-row-between items-center'}>
                <span>الملفات و المحلقات</span>
                <FButton>إضافة ملف</FButton>
              </div>
            </>
          )}
          {chosenDetails === 'activities' && (
            <>
              <span>29 يونيو 2022</span>
              <div className={'activities-wrapper mt-5'}>
                <div className={'flex flex-col gap-10 '}>
                  <ActivityLog
                    time={'12:11:51 PM'}
                    text={'محاولة ناجة للتواصل مع  العميل'}
                    color={'green'}
                  />
                  <ActivityLog
                    time={'12:11:51 PM'}
                    text={'محاولة غير ناجة للتواصل مع العميل'}
                    color={'red'}
                  />
                </div>
              </div>
            </>
          )}
          {chosenDetails === 'transactions' && (
            <>
              <Transactions phoneNumber={phoneNumber} />
              <CustomerResiduals customerPhoneNumber={phoneNumber} />
              <CustomerRefunds customerPhoneNumber={phoneNumber} />
            </>
          )}
        </div>
      </>
    )
  }
  const Breadcrumb = () => (
    <>
      <div
        className={
          'f-row-between items-center border-b  bg-gray-50 p-2  dark:bg-gray-700'
        }
      >
        <div className={'flex gap-1'}>
          <span>العملاء</span>
          <span>/</span>
          <span> {response?.customer?.phoneNumber}</span>
        </div>

        <div className={'f-row-center-center gap-2'}>
          <FButton btnType={'secondary'}>
            <FIconWrapper>
              <HiOutlinePhoneOutgoing />
              <span>مكالمة</span>
            </FIconWrapper>
          </FButton>
          <FButton btnType={'secondary'}>
            <FIconWrapper>
              <HiOutlineAnnotation />
              <span>رسالة نصية</span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    </>
  )

  const handleFormSubmission = values => {
    setIsEditRequestLoading(true)
    axiosInstance
      .post(`customer/${phoneNumber}`, values)
      .then(res => {
        setIsEditRequestLoading(false)
        setIsEditModalOpen(false)
        toast.success('تم تعديل بيانات العميل بنجاح')
        Refetch()
      })
      .catch(err => {
        setIsEditRequestLoading(false)
        toast.error(err.response.data.message)
      })
  }
  const ValidationSchema = Yup.object().shape({
    customerName: Yup.string().required('الاسم مطلوب'),
  })

  if (loading || callsLogs.loading) return <span>Loading...</span>
  else
    return (
      <>
        <CallModal
          phone={response?.customer?.phoneNumber}
          isOpen={isCallModalOpen}
          setIsOpen={setIsCallModalOpen}
        />
        <FModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          title={'تعديل البيانات'}
        >
          <Formik
            validationSchema={ValidationSchema}
            enableReinitialize={true}
            initialValues={{
              customerName: response.customer.name
                ? response.customer.name
                : '',
            }}
            onSubmit={handleFormSubmission}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={'w-full rounded-md'}
              >
                <SFormWrapper>
                  <FLabel htmlFor={'customerName'} className={'w-full'}>
                    الاسم
                  </FLabel>
                  <FInputField
                    name={'customerName'}
                    id={'customerName'}
                    type={'text'}
                    placeholder={`أدخل الاسم`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customerName}
                  />
                  <EFormInvalidInput
                    errors={errors}
                    touched={touched}
                    FieldName={'customerName'}
                  />
                </SFormWrapper>
                <div>
                  <FButton type={'submit'}>
                    <FIconWrapper>
                      <span>أضافة</span>
                      <ESpinner isVisible={isEditRequestLoading} />
                    </FIconWrapper>
                  </FButton>
                </div>
              </form>
            )}
          </Formik>
        </FModal>
        <div className={'flex h-full flex-grow flex-col bg-gray-500'}>
          <Breadcrumb />
          <div className={'flex  flex-grow'}>
            <div
              className={
                ' f-col h-full w-full border-l  bg-gray-50  dark:bg-gray-700'
              }
            >
              <CustomerCardInfo />
              <div className={'flex '}>
                <div className={'w-8/12'}>
                  <div
                    className={
                      'primary-shadow   flex h-fit flex-grow   border bg-white dark:bg-gray-900'
                    }
                  >
                    <CustomerContactDetails />
                  </div>
                </div>
                <div className={'flex h-fit w-4/12 flex-col gap-2 px-3'}>
                  {callsLogs?.response?.archiveCallsSchedule?.map(
                    (item, keyId) => (
                      <div
                        key={keyId}
                        className={
                          'primary-shadow  mt-3 flex flex-grow rounded border bg-white dark:bg-gray-900'
                        }
                      >
                        <div className={'flex w-full flex-col gap-2 p-3'}>
                          <div className={'flex items-center justify-between'}>
                            <div className={'flex gap-2'}>
                              <FAvatar name={item?.user?.name} />
                              <div className={'flex flex-col'}>
                                <div className={'flex flex-col'}>
                                  <span
                                    className={'block  text-sm font-medium '}
                                  >
                                    {item?.user?.name ?? 'N/A'}
                                  </span>
                                  <span className={'block text-xs '}>
                                    {item?.archiveByEmail}
                                  </span>
                                </div>
                                <span className={'text-xs'}>
                                  {item?.user?.phoneNumber}
                                </span>
                              </div>
                            </div>
                            <div className={'flex gap-2'}>
                              <span className={'text-xs'}></span>
                              <span className={'text-xs'}>
                                {item?.archiveTime}
                              </span>
                              <span className={'text-xs'}>
                                {moment(item?.archiveDate).format('DD/MM/YYYY')}
                              </span>
                            </div>
                          </div>

                          <div className={'flex  justify-between '}>
                            <span className={'text-sm font-bold'}>
                              {getProductById(item?.productId)?.name}
                            </span>
                            <span className={'text-sm font-bold'}>
                              {ARCHIVESOURCE[item?.archiveSource] ?? ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}
export default CustomerDetails
