import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as Yup from 'yup'
import { Formik } from 'formik'
import SFormWrapper from '../../components/EFormWrapper'
import FInputField from '../../components/FInputField'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import ESpinner from '../../components/ESpinner'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import { BASE_URL, SITE_LOGO } from '../../env'
import FLabel from '../../components/FLabel'
import { useTranslation } from 'react-i18next'
import { decodeJwtToken } from '../../helpers/utils'
import FIconWrapper from '../../components/FIconWrapper'

const Login = () => {
  const { t } = useTranslation()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isInvalidServerValidation, setIsInvalidServerValidation] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      return navigate('/')
    }
  }, [])
  const handleRequestBody = values => {
    const requestBody = {
      email: values.userName,
      password: values.password,
    }
    return requestBody
  }
  const fetchProducts = token => {
    axios
      .get(`${BASE_URL}product/listAllProducts?page=${0}&size=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(res => {
        localStorage.setItem('products', JSON.stringify(res.data.products))

        axios
          .get(`${BASE_URL}user/?page=0&size=20`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'ngrok-skip-browser-warning': '69420',
            },
          })
          .then(resProduct => {
            localStorage.setItem(
              'users',
              JSON.stringify(resProduct.data.userDtoList),
            )
            window.location.replace('/')
          })
          .catch(err => {
            window.location.replace('/')
          })
        localStorage.setItem(
          'products',
          JSON.stringify(res.data.products || []),
        )
      })
      .catch(err => {
        window.location.replace('/')
      })
  }
  const fetchAgents = token => {
    axios
      .get(`${BASE_URL}user/?page=0&size=20`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(res => {
        localStorage.setItem(
          'users',
          JSON.stringify(res.data.userDtoList || []),
        )
        window.location.replace('/')
      })
      .catch(err => {
        console.log(err)
        window.location.replace('/')
      })
  }
  // axios.defaults.withCredentials = true
  const handleFormSubmission = values => {
    setIsRequestLoading(true)
    setIsInvalidServerValidation('')
    axios
      .post(`${BASE_URL}user/login`, handleRequestBody(values))
      .then(response => {
        // localStorage.setItem('access_token', response.data.token)
        const { AUTHORITIES, sub, NAME } = decodeJwtToken(response.data.token)
        fetchProducts(response.data.token)
        localStorage.setItem('access_token', response.data.token)
        localStorage.setItem('authority', JSON.stringify(AUTHORITIES))
        localStorage.setItem('name', JSON.stringify(NAME))
        localStorage.setItem('email', JSON.stringify(sub))
      })
      .catch(error => {
        setIsRequestLoading(false)

        if (error.response.status === 0) {
          setIsInvalidServerValidation(`Server unreachable. Contact support `)
        } else {
          setIsInvalidServerValidation(`${error?.response?.data?.message}`)
        }
      })
  }
  const ValidationSchema = Yup.object().shape({
    userName: Yup.string()
      .required('البريد الالكتروني مطلوب')
      .email('البريد الالكتروني غير صحيح'),
    password: Yup.string().required('كلمة المرور مطلوبة'),
  })
  return (
    <>
      <div className={'f-col-center-center   h-screen  w-full bg-orient-500'}>
        <div className={'rounded  lg:w-1/3  lg:p-2  '}>
          <Formik
            validationSchema={ValidationSchema}
            initialValues={{
              userName: '',
              password: '',
            }}
            onSubmit={handleFormSubmission}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={
                  'rounded-md border    bg-gray-50 p-5  dark:bg-gray-700'
                }
              >
                <div className={'f-col-center-center mb-8'}>
                  <img
                    src={SITE_LOGO}
                    width={100}
                    alt={'logo-image'}
                    className={'rounded-lg'}
                  />
                </div>
                <span className={'block text-center text-2xl'}>
                  تسجيل الدخول
                </span>
                <span
                  className={
                    'mb-5 block text-center text-sm  text-gray-500 dark:text-gray-100  '
                  }
                >
                  مرحباً بك, من فضلك قم بتسجيل الدخول لحسابك
                </span>
                <SFormWrapper>
                  <FLabel htmlFor={'userName'}> {t('email')} </FLabel>
                  <FInputField
                    type='text'
                    name={'userName'}
                    id={'userName'}
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'أدخل البريد الإلكتروني'}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'userName'}
                  />
                </SFormWrapper>
                <SFormWrapper>
                  <label htmlFor={'password'}>{t('password')}</label>

                  <div className={'relative'}>
                    <FInputField
                      type={`${isPasswordVisible ? 'text' : 'password'}`}
                      name={'password'}
                      id={'password'}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'أدخل كلمة المرور'}
                    />
                    <EFormInvalidInput
                      touched={touched}
                      errors={errors}
                      FieldName={'password'}
                    />
                    <HiOutlineEye
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      size={20}
                      className={`absolute left-2 top-2 ${
                        !isPasswordVisible ? 'hidden' : 'block'
                      }`}
                    />
                    <HiOutlineEyeOff
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      size={20}
                      className={`absolute left-2 top-2 ${
                        isPasswordVisible ? 'hidden' : 'block'
                      }`}
                    />
                  </div>
                </SFormWrapper>
                <SFormWrapper>
                  <FButton
                    disabled={isRequestLoading}
                    type={'submit'}
                    className={'mt-3 w-full'}
                  >
                    <FIconWrapper>
                      <ESpinner isVisible={isRequestLoading} />
                      {t('login')}
                    </FIconWrapper>
                  </FButton>
                </SFormWrapper>
                <div className={'mt-3'}>
                  {isInvalidServerValidation !== '' ? (
                    <div
                      className={'rounded border border-red-500 bg-red-100 p-2'}
                    >
                      <span
                        className={'block text-center font-medium text-red-800'}
                      >
                        {' '}
                        {isInvalidServerValidation}{' '}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default Login
