import FModal from '../../../components/FModal'
import { useState } from 'react'
import { useFormik } from 'formik'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'
import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import { toast } from 'react-toastify'

const CustomerNewSubModal = ({ phoneNumber }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { data, loading, error } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const response = await axiosInstance(
        '/product/listAllProducts?page=0&size=10',
      )
      const excludedProductsIds = [232, 221020, 253]
      return response.data.products
        .filter(product => !excludedProductsIds.includes(product.id))
        .map(product => ({ label: product.name, id: product.id }))
    },
  })

  const mutation = useMutation({
    mutationKey: 'addSubscription',
    mutationFn: async values => {
      return axiosInstance.post('/customersubscription', {
        productId: values.product,
        customerPhoneNumber: phoneNumber,
        answers: [],
      })
    },
    onSuccess: () => {
      toast.success('تمت الاضافة بنجاح')
      setIsOpen(false)
    },
    onError: error => {
      toast.error('حدث خطأ اثناء الاضافة')
    },
  })

  const formik = useFormik({
    initialValues: {
      product: '',
    },
    onSubmit: values => {
      console.log(values)
      mutation.mutate(values)
    },
  })
  return (
    <div>
      <FButton type={'button'} onClick={() => setIsOpen(true)}>
        اضافة اشتراك جديد
      </FButton>
      <FModal isOpen={isOpen} setIsOpen={setIsOpen} title='اشتراك جديد'>
        <form onSubmit={formik.handleSubmit}>
          {loading ? (
            <div>
              <select>
                <option>جاري التحميل</option>
              </select>
            </div>
          ) : (
            <>
              <EFormWrapper>
                <FLabel>اختر المنتج الذي تريد الاشتراك به</FLabel>
                <select
                  name='product'
                  value={formik.values.product}
                  onChange={formik.handleChange}
                >
                  <option value=''>اختر المنتج الذي تريد الاشتراك به</option>
                  {data?.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.label}
                    </option>
                  ))}
                </select>
              </EFormWrapper>
            </>
          )}

          <FRow className={'mt-4'}>
            <FButton
              btnType={'secondary'}
              type={'button'}
              onClick={() => setIsOpen(false)}
            >
              الغاء
            </FButton>
            <FButton type={'submit'}>
              {mutation.isPending ? 'جاري الاضافة' : 'اضافة'}
            </FButton>
          </FRow>
        </form>
      </FModal>
    </div>
  )
}
export default CustomerNewSubModal
